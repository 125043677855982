@import "styles/fonts.css";

body {
  margin: 0;
  font-family: "Space Grotesk Regular" !important;
  font-style: normal !important;
  font-weight: normal !important;
  color: #000000 !important;
  font-size: 16px !important;
}

button:focus{
  outline: 0px;
}

a {
  color: #000 !important;
}

a:hover{
  text-decoration: none !important;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 14px;
}

.font-medium{
  font-family: "Space Grotesk Medium";
}

.pointer {
  cursor: pointer;
}

.bottom-0 {
  bottom: 0;
}

.w-table {
  width: 100px;
}

h3 {
  margin-bottom: 0 !important;
}

.margin-top-48 {
  margin-top: 48px !important;
}

.margin-bottom-48 {
  margin-bottom: 48px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-top-32 {
  margin-top: 32px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}


.margin-top-12{
  margin-top: 12px !important;
}

.margin-bottom-12{
  margin-bottom: 12px !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent !important;
}

.pointer {
  cursor: pointer;
}

/* Colors classes */

.page1-column,
.bg-page-1 {
  background-color: #00d739;
}

.color-page-1 {
  color: #00d739;
}

.link-page-1{
  border-bottom: 1px solid #00d739;
}

.link-page-2{
  border-bottom: 1px solid #1abcfe;
}

.link-page-3{
  border-bottom: 1px solid #ffb400;
}

.link-page-4{
  border-bottom: 1px solid #f24e1e;
}

.page2-column,
.bg-page-2 {
  background-color: #1abcfe;
}

.color-page-2 {
  color: #1abcfe;
}

.page3-column,
.bg-page-3 {
  background-color: #ffb400;
}

.color-page-3 {
  color: #ffb400;
}

.page4-column,
.bg-page-4 {
  background-color: #f24e1e;
}

.color-page-4 {
  color: #f24e1e;
}

.desktop-container {
  overflow: auto;
}

/* Hide scrollbar */

.desktop-container::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
}

.link-column {
  width: 64px;
  position: relative;
  cursor: pointer;
}

.link-column > p {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  transform-origin: center;
  transform: translateY(-50%) translateX(-50%) rotate(-90deg);
}

@media screen and (min-width: 1920px) {
  .body-page {
    padding-left: 200px;
    padding-right: 200px;
  }
}

.body-page {
  padding-top: 80px;
}

.title-page {
  text-transform: uppercase;
  font-family: "Space Grotesk Regular";
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0px;
}

/* Tempo di lettura */

.tempo-di-lettura {
  margin-left: 22px;
}

.block-condividi {
  margin-top: 16px;
  margin-bottom: 16px;
}

.block-condividi img {
  margin-left: 16px;
}

.tempo-di-lettura > .linea {
  width: 72px;
  height: 2px;
}

.tempo-di-lettura > .box-lettura {
  width: 24px;
  height: 24px;
  font-family: "Space Grotesk Medium";
  font-size: 16px;
  padding-left: 6px;
  padding-top: 2px;
}

/* Number Big Page */

.number-page {
  font-size: 72px;
  font-weight: 500;
  line-height: 30px;
  font-feature-settings: "pnum" on, "lnum" on;
  float: left;
  margin-left: 0px;
  margin-top: 23px;
  margin-bottom: 26px;
  margin-right: 46px;
}

/* Number for page 4 */

.number-page-4 {
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 97px;
  font-feature-settings: "pnum" on, "lnum" on;
}

/* Class for text general page */

.text-page {
  margin-top: 32px;
  font-size: 22px;
  text-indent: 0;
  line-height: 30px;
}
/* External Link verticali */

.external-link-page-1{
  font-size: 14px;
  line-height: 16px;
  padding: 8px;
  border: 2px solid #00d739;
  text-align: center;
  font-family: "Space Grotesk Medium";
}

.external-link-page-3{
  font-size: 14px;
  line-height: 16px;
  padding: 8px;
  border: 2px solid #ffb400;
  font-family: "Space Grotesk Medium";
  text-align: center;
}


.external-link-vertical {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  position: absolute;
  top: 50%;
  text-align: center;
  padding-top: 35px;
  white-space: nowrap;
  transform-origin: center;
  transform: translateY(-50%) translateX(-50%) rotate(-90deg);
}

.external-link-vertical-16 {
  display: block;
  writing-mode: vertical-rl;
  transform: scale(-1);
  font-family: "Space Grotesk Medium";
  margin-top: 10px;
}

/* Citazioni */

.citazione {
  padding-bottom: 40px;
  padding-right: 64px;
  padding-left: 32px;
  font-weight: 500;
  font-size: 36px;
  margin-top: 40px;
  line-height: 48px;
}

.citazione-page-1 {
  border-bottom: 3px solid #00d739;
  border-right: 3px solid #00d739;
}

.citazione-page-2 {
  border-bottom: 3px solid #1abcfe;
  border-right: 3px solid #1abcfe;
}

.citazione-page-3 {
  border-bottom: 3px solid #ffb400;
  border-right: 3px solid #ffb400;
}

.citazione-page-4 {
  border-bottom: 3px solid #f24e1e;
  border-right: 3px solid #f24e1e;
}

.citazione-nome {
  font-style: normal;
  font-family: "Space Grotesk Medium";
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px;
}

/* Share Button */

.condividi {
  margin-top: 64px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 84px;
}

.condividi img {
  margin-left: -4px;
}

.condividi span {
  margin-top: 2px;
}

/* Footer */

.Footer {
  margin-top: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.icon-page-1:hover {
  fill: #00d739 !important;
}

.icon-stroke-page-1:hover > circle {
  stroke: #00d739;
}

.icon-page-2:hover {
  fill: #1abcfe !important;
}

.icon-stroke-page-2:hover > circle {
  stroke: #1abcfe;
}

.icon-page-3:hover {
  fill: #ffb400 !important;
}

.icon-stroke-page-3:hover > circle {
  stroke: #ffb400;
}

.icon-page-4:hover {
  fill: #f24e1e !important;
}

.icon-stroke-page-4:hover > circle {
  stroke: #f24e1e;
}

.border-footer-page-one {
  border-top: 4px solid #00d739;
}

.border-footer-page-two {
  border-top: 4px solid #1abcfe;
}

.border-footer-page-three {
  border-top: 4px solid #ffb400;
}

.border-footer-page-four {
  border-top: 4px solid #f24e1e;
}

.privacy {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  margin-top: 32px;
}

.small-footer {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.subtitle-footer {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.social-icons {
  margin-top: 70px;
}

/* Borders */

.hr-page-1 {
  border-top: 1px solid #00d739;
  margin-top: 4px;
  margin-bottom: 4px;
}

.hr-page-3 {
  border-top: 1px solid #ffb400;
  margin-top: 4px;
  margin-bottom: 4px;
}

.hr-page-2 {
  border-top: 4px solid #1abcfe;
  margin-top: 4px;
  margin-bottom: 32px;
}

.hr-page-4 {
  border-top: 4px solid #f24e1e;
  margin-top: 4px;
  margin-bottom: 32px;
}

.border-top-mobile-3 {
  border-top: 2px solid #f24e1e;
  margin: 24px auto;
  width: 200px;
}

.border-top-mobile-4 {
  border-top: 2px solid #f24e1e;
  padding-top: 24px;
  padding-bottom: 24px;
}

.border-small-page-3 {
  width: 230px;
  margin: 0 auto;
  height: 4px;
  margin-bottom: 24px;
  background-color: #ffb400;
}

.border-top-bottom-three {
  border-top: 4px solid #ffb400;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 4px solid #ffb400;
}

.border-right-page-four {
  border-right: 4px solid #f24e1e;
  padding-right: 30px;
  padding-top: 30px;
  font-family: "Space Grotesk Medium";
  line-height: 21px;
  padding-bottom: 30px;
}

.border-bottom-page-4 {
  border-bottom: 2px solid #f24e1e;
}

.border-left-page-three {
  border-left: 4px solid #ffb400;
  margin-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: "Space Grotesk Regular";
  line-height: 21px;
}

/* Variuous classes */

.subtitle {
  font-family: "Space Grotesk Medium";
  font-size: 20px;
  line-height: 21px;
  margin-top: 48px;
  text-transform: uppercase;
}

.subtitle-regular {
  font-family: "Space Grotesk Regular";
  font-size: 20px;
  line-height: 21px;
  margin-top: 48px;
  text-transform: uppercase;
}

.mini-subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.big-number {
  font-weight: 500;
  font-size: 160px;
  line-height: 216px;
  font-feature-settings: "pnum" on, "lnum" on;
}

.big-number-unit {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
}

.medium-number {
  font-weight: 500;
  font-size: 60px;
  line-height: 81px;
  font-feature-settings: "pnum" on, "lnum" on;
  margin-right: 24px;
}

.medium-number-label {
  font-family: "Space Grotesk Medium";
  font-size: 16px;
  line-height: 21px;
}

.continua-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.slogan-strong {
  font-family: "Space Grotesk SemiBold";
  font-size: 18px;
  line-height: 24px;
}

.label-page-4{
  font-family: "Space Grotesk SemiBold";
  line-height: 21px;
}

.description-page-4{
  font-family: "Space Grotesk Medium";
  line-height: 21px;
}

.consumi-aziende {
  margin-top: 54px !important;
}

.text-page-three,
.text-page-four {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  padding-left: 30px;
  padding-top: 5px;
}

#page1,
#page2,
#page3,
#page4 {
  padding-top: 70px;
  margin-top: -70px;
}


