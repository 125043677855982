/* Screen rotation message */

.box-landscape {
  width: 100%;
  height: 100vh;
  text-align: center;
  justify-content: center;
}

.rotate-smartphone {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 43px;
  border-bottom: 3px solid #000;
}

@media (orientation: portrait) {
  .box-landscape {
    display: none !important;
  }
}

@media (orientation: landscape) {
  .mobile-pages {
    display: none;
  }
  .box-landscape {
    display: flex;
  }
}
