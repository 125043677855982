@import "styles/fonts.css";

@media screen and (max-width: 1279px) {
  .menu-mobile {
    height: 65px;
    top: 5px;
    z-index: 1000;
    font-family: "Space Grotesk SemiBold";
    font-size: 14px;
    background-color: #fff;
  }
  
  .menu-mobile > .menu-icons {
    padding: 18px 23px 23px 18px;
  }
  
  .menu-open {
    height: calc(100vh - 70px);
    top: 70px;
    font-family: "Space Grotesk SemiBold";
    font-size: 24px;
    line-height: 27px;
    color: #ffffff;
    text-transform: uppercase;
  }
  
  .menu-elements {
    display: flex;
    flex-direction: column;
  }
  
  .item-menu-mobile {
    height: calc(25vh - 17px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
  }
  
  .top-menu {
    height: 70px;
    position: sticky;
    top: 0;
    z-index: 10000;
    background-color: #fff;
  }
  
  .bar-progress {
    height: 5px;
    display: flex;
    top: 0;
  }
  
  .bar-progress > div {
    height: 5px;
  }
  
  .body-mobile {
    top: 70px;
  }
  
  .body-page-mobile {
    scroll-margin-top: 70px;
  }

  .title-page {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-transform: uppercase;
  }
  
  .tempo-di-lettura {
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .subtitle {
    font-family: "Space Grotesk Medium";
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
  }

  .number-page {
    font-size: 56px;
    line-height: 30px;
    font-feature-settings: "pnum" on, "lnum" on;
    float: left;
    margin-left: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 32px;
  }

  .big-number {
    font-weight: 500;
    font-size: 120px;
    line-height: 162px;
    font-feature-settings: "pnum" on, "lnum" on;
  }

  .big-number-unit {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 41px;
  }

  .medium-number {
    font-weight: 500;
    font-size: 48px;
    line-height: 65px;
    font-feature-settings: "pnum" on, "lnum" on;
    margin-right: 24px;
  }

  .medium-number-label {
    font-family: "Space Grotesk Medium";
    font-size: 14px;
    line-height: 18px;
    margin-top: -5px;
  }

  .slogan-strong {
    font-size: 16px;
    line-height: 18px;
  }

  /* Number for page 4 */

  .number-page-4 {
    font-style: normal;
    font-weight: normal;
    font-size: 56px;
    line-height: 30px;
    font-feature-settings: "pnum" on, "lnum" on;
  }

  .text-page {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-top: 24px;
  }

  .citazione {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    padding: 0;
    line-height: 32px;
    padding-bottom: 20px;
  }

  .citazione-page-1 {
    border-bottom: 4px solid #00d739;
    border-right: 0;
  }

  .citazione-page-2 {
    border-bottom: 4px solid #1abcfe;
    border-right: 0;
  }

  .citazione-page-3 {
    border-bottom: 4px solid #ffb400;
    border-right: 0;
  }

  .citazione-page-4 {
    border-bottom: 4px solid #f24e1e;
    border-right: 0;
  }

  .citazione-nome {
    font-style: normal;
    font-family: "Space Grotesk Medium";
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
  }

  .external-link-vertical-16 {
    margin-top: 10px;
    writing-mode: inherit;
    transform: none;
  }

  .hr-divide-page-1 {
    border-top: 4px solid #00d739;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .hr-divide-page-3 {
    border-top: 4px solid #ffb400;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .hr-divide-page-2 {
    border-top: 4px solid #1abcfe;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .hr-page-2 {
    border-top: 4px solid #1abcfe;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .hr-divide-page-4 {
    border-top: 4px solid #f24e1e;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .Footer {
    padding-top: 8px;
    padding-bottom: 40px;
    margin-top: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {

.item-menu-mobile {
  height: calc(50vh - 35px);
  width: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}

.menu-elements {
  display: flex;
  flex-direction: row;
}
}