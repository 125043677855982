@font-face {
  src: url("fonts/SpaceGrotesk-Regular.woff2") format("woff2"),
    url("fonts/SpaceGrotesk-Regular.woff") format("woff");
  font-family: "Space Grotesk Regular";
}

@font-face {
  src: url("fonts/SpaceGrotesk-Light.woff2") format("woff2"),
    url("fonts/SpaceGrotesk-Light.woff") format("woff");
  font-family: "Space Grotesk Light";
}

@font-face {
  src: url("fonts/SpaceGrotesk-Bold.woff2") format("woff2"),
    url("fonts/SpaceGrotesk-Bold.woff") format("woff");
  font-family: "Space Grotesk Bold";
}

@font-face {
  src: url("fonts/SpaceGrotesk-SemiBold.woff2") format("woff2"),
    url("fonts/SpaceGrotesk-SemiBold.woff") format("woff");
  font-family: "Space Grotesk SemiBold";

}

@font-face {
  src: url("fonts/SpaceGrotesk-Medium.woff2") format("woff2"),
    url("fonts/SpaceGrotesk-Medium.woff") format("woff");
  font-family: "Space Grotesk Medium";

}
